import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { cartReducer, wishlistReducer } from "./reducers/cartReducers";
import {
  orderDetailsReducer,
  orderHistoryReducer,
} from "./reducers/orderReducer";
import {
  allCollectionsReducer,
  categoryReducer,
  currencyConverterReducer,
  footerDataReducer,
  homePageReducer,
  particularCollectionsReducer,
  productDetailsReducer,
  searchProductReducer,
  selectCountryReducer,
} from "./reducers/productReducers";
import { userDetailsReducer } from "./reducers/userReducers";

const initialState = {
  userDetails: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : {},
  },
  cart: {
    cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],
    shippingAddress: localStorage.getItem("shippingAddress")
      ? JSON.parse(localStorage.getItem("shippingAddress"))
      : {},
    billingAddress: localStorage.getItem("billingAddress")
      ? JSON.parse(localStorage.getItem("billingAddress"))
      : {},
  },
  wishlist: {
    // wishlistItems: localStorage.getItem('wishlistItems') ? JSON.parse(localStorage.getItem("wishlistItems")) : [],
  },
  country: localStorage.getItem("country")
    ? JSON.parse(localStorage.getItem("country"))
    : "",
};

const reducer = combineReducers({
  country: selectCountryReducer,
  searchProducts: searchProductReducer,
  productDetailsSearch: productDetailsReducer,
  cart: cartReducer,
  wishlist: wishlistReducer,
  homePage: homePageReducer,
  allCollections: allCollectionsReducer,
  particularCollection: particularCollectionsReducer,
  category: categoryReducer,
  orderDetails: orderDetailsReducer,
  orderHistory: orderHistoryReducer,
  userDetails: userDetailsReducer,
  footerData: footerDataReducer,
  currencyData: currencyConverterReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
