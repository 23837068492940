export function setPremitives(data, x, cartItems) {
  if (x) data.tempColour = x;
  else data.tempColour = data.variations[0].Images[0].colour;

  let tempColour = [],
    tempLink = [],
    tempColourlink = [];
  data.imageArr = [];
  data.uniqueImages = [];
  data.allImages = [];

  for (let i = 0; i < data.variations.length; i++) {
    let Images = data.variations[i].Images;

    for (let j = 0; j < Images.length; j++) {
      if (
        Images[j].colour === data.tempColour &&
        !tempColourlink.includes(Images[j].link)
      ) {
        data.imageArr.push(Images[j]);
        tempColourlink.push(Images[j].link);
      }

      if (!tempLink.includes(Images[j].link)) {
        data.allImages.push(Images[j]);
        tempLink.push(Images[j].link);
      }

      if (!tempColour.includes(Images[j].colour)) {
        data.uniqueImages.push(Images[j]);
        tempColour.push(Images[j].colour);
      }
    }

    if (!data.Size.includes(data.variations[i].Size))
      data.Size.push(data.variations[i].Size);

    if (
      data.variations[i].Height &&
      !data.Height.includes(data.variations[i].Height)
    )
      data.Height.push(data.variations[i].Height);

    if (data.variations[i].GSM && !data.GSM.includes(data.variations[i].GSM))
      data.GSM.push(data.variations[i].GSM);
  }

  data.imageSrc = data.imageArr[0];

  data.qty = 1;

  return data;
}
