import * as React from 'react';
// import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function LoadingBox() {
  return (
    <div style={{ position: "fixed", top: "0", left: '0' }}>
      <Box sx={{ width: '100%', position: "fixed", zIndex: "1", background: "rgba(0,0,0,0.2)", scale: "1", height: "100vh", width: "100%" }}>
        {/* <div style={{ position: "fixed", height: "100%", width: "100%",  }}> */}
        <div style={{ position:"absolute", top:"50%", left:"50%" }}>
          <CircularProgress />
        </div>
        {/* </div> */}
      </Box>
    </div>

  );
}
