import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getFooterData } from "../actions/productActions";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsPinterest,
  BsTwitter,
} from "react-icons/bs";

export default function Footer() {
  function FullHeight(id) {
    let divs = [
      "byadab",
      "collections",
      "assistance",
      "services",
      "andmore",
      "contact",
    ];
    for (let i = 0; i < divs.length; i++) {
      document.getElementById(divs[i]).className = "footerChild";
    }

    let el = document.getElementById(id);
    el.className =
      el.className === "footerChild" ? "fullHeight" : "footerChild";
  }

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getFooterData());
  }, []);

  const footerDetails = localStorage.getItem("footerDetails")
    ? JSON.parse(localStorage.getItem("footerDetails"))
    : null;

  return (
    <div className="footer row1 center" style={{ flexDirection: "column" }}>
      <div className="mainFooter">
        <div className="footerChild" id="byadab">
          <h3 onClick={() => FullHeight("byadab")}>BY ADAB</h3>
          <ul>
            <li>
              <Link to="/why-by-adab">WHY BY ADAB</Link>
            </li>
            <li>
              <Link to="contact-us">CONTACT US</Link>
            </li>
          </ul>
        </div>

        <div className="footerChild" id="collections">
          <h3 onClick={() => FullHeight("collections")}>COLLECTIONS</h3>
          <ul>
            {footerDetails &&
              footerDetails.collections &&
              footerDetails.collections.map((x) => (
                <li key={x}>
                  <Link to={`/collection/${x}`}>{x}</Link>
                </li>
              ))}
          </ul>
        </div>

        <div className="footerChild" id="assistance">
          <h3 onClick={() => FullHeight("assistance")}>ASSISTANCE</h3>
          <ul>
            <li>
              <Link to="/shipping-delivery">SHIPPING DELIVERY</Link>
            </li>
            <li>
              <Link to="/return-exchange">RETURNS EXCHANGES</Link>
            </li>
            <li>
              <Link to="/privacy-policy">PRIVACY POLICY</Link>
            </li>
            <li>
              <Link to="/international-shipping">INTERNATIONAL-SHIPPING</Link>
            </li>
          </ul>
        </div>

        <div className="footerChild" id="services">
          <h3 onClick={() => FullHeight("services")}>SERVICES</h3>
          <ul>
            <li>
              <Link to="hotel-collections">HOTEL COLLECTION</Link>
            </li>
            <li>
              <Link to="corporate-orders">CORPORATE ORDERS</Link>
            </li>
            <li>
              <Link to="fit-to-bed">FIT TO BED</Link>
            </li>
            <li>
              <Link to="wedding-shop">WEDDING SHOP</Link>
            </li>
          </ul>
        </div>

        <div className="footerChild" id="andmore">
          <h3 onClick={() => FullHeight("andmore")}>AND MORE</h3>
          <ul>
            <li>
              <Link to="fabrics">FABRICS</Link>
            </li>
            <li>
              <Link to="size-guide">SIZE GUIDE</Link>
            </li>
            <li>
              <Link to="care-instructions">CARE INSTRUCTIONS</Link>
            </li>
            <li>
              <Link to="faqs">FAQ'S</Link>
            </li>
            <li>
              <Link to="franchise">FRANCHISE</Link>
            </li>
            <li>
              <Link to="career">CAREER</Link>
            </li>
            {/* <li>
              <Link to="our-partners">ENERGY PARTNERS</Link>
            </li> */}
          </ul>
        </div>

        <div className="footerChild" id="contact">
          <h3 onClick={() => FullHeight("contact")}>CONTACT</h3>
          <ul>
            <li>
              <strong>Legal Name :</strong>
              <br /> BY ADAB PRIVATE LIMITED
            </li>

            <li>
              <strong>EMAIL :</strong>
              <br></br>CARE@BYADAB.COM
            </li>

            <li>
              <strong>HELPLINE :</strong>
              <br></br>+91-98181 33733
            </li>
          </ul>
        </div>
      </div>

      <div className="BottomFooter"> &copy;2024 BY ADAB</div>
      <div className="footer-social-icons flex">
        <Link
          className="social-child"
          to="https://www.instagram.com/byadab/"
          target="blank"
          aria-label="Connect with Byadab Instagram Page"
        >
          <BsInstagram />
        </Link>
        <Link
          className="social-child"
          to={"https://www.facebook.com/Byadabhomes/"}
          target="blank"
          aria-label="Connect with Byadab Facebook Page"
        >
          <BsFacebook />
        </Link>
        <Link
          className="social-child"
          target="blank"
          aria-label="Connect with Byadab Pinterest Page"
          to={"https://in.pinterest.com/byadab/"}
        >
          <BsPinterest />
        </Link>
        <Link
          className="social-child"
          target="blank"
          to={"https://www.linkedin.com/in/byadab/?originalSubdomain=in"}
          aria-label="Connect with Byadab Linkedin Page"
        >
          <BsLinkedin />
        </Link>
        <Link
          className="social-child"
          to={"https://twitter.com/Byadab"}
          aria-label="Connect with Byadab Twitter Page"
        >
          <BsTwitter />
        </Link>
      </div>
    </div>
  );
}
