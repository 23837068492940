import {
  ALL_COLLECTIONS_FAIL,
  ALL_COLLECTIONS_REQUEST,
  ALL_COLLECTIONS_SUCCESS,
  CATEGORYDATA_FAIL,
  CATEGORYDATA_REQUEST,
  CATEGORYDATA_SUCCESS,
  CURRENCY_DATA_FAIL,
  CURRENCY_DATA_REQUEST,
  CURRENCY_DATA_SUCCESS,
  FOOTER_DATA_FAIL,
  FOOTER_DATA_REQUEST,
  FOOTER_DATA_SUCCESS,
  HOMEPAGEDATA_FAIL,
  HOMEPAGEDATA_REQUEST,
  HOMEPAGEDATA_SUCCESS,
  PARTICULAR_COLLECTION_FAIL,
  PARTICULAR_COLLECTION_REQUEST,
  PARTICULAR_COLLECTION_SUCCESS,
  PRODUCTS_DETAILS_FAIL,
  PRODUCTS_DETAILS_REQUEST,
  PRODUCTS_DETAILS_SUCCESS,
  PRODUCTS_HEADER_SUCCESS,
  PRODUCTS_MATCHED_SUCCESS,
  PRODUCTS_SEARCH_FAIL,
  PRODUCTS_SEARCH_REQUEST,
  PRODUCTS_SEARCH_RESET,
  PRODUCTS_SEARCH_SUCCESS,
  PRODUCTS_SIMILAR_SUCCESS,
  REFRESH_SEARCH_SCREEN,
  SAVE_SEARCH_PRODUCTS,
  SELECT_COUNTRY_FAILUER,
  SELECT_COUNTRY_SUCCESS,
  SUCCESS_SELECT_COUNTRY_DATA,
} from "../constants/productConstants";

export const currencyConverterReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case CURRENCY_DATA_REQUEST:
      return { loading: true };
    case CURRENCY_DATA_SUCCESS:
      return { ...state, loading: false, currencies: action.payload };
    case CURRENCY_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const homePageReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case HOMEPAGEDATA_REQUEST:
      return { loading: true };
    case HOMEPAGEDATA_SUCCESS:
      return { ...state, loading: false, homePageData: action.payload };
    case HOMEPAGEDATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const categoryReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case CATEGORYDATA_REQUEST:
      return { loading: true };
    case CATEGORYDATA_SUCCESS:
      return { ...state, loading: false, categoryData: action.payload };
    case CATEGORYDATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allCollectionsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case ALL_COLLECTIONS_REQUEST:
      return { loading: true };
    case ALL_COLLECTIONS_SUCCESS:
      return { ...state, loading: false, collectionsData: action.payload };
    case ALL_COLLECTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const particularCollectionsReducer = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case PARTICULAR_COLLECTION_REQUEST:
      return { loading: true };
    case PARTICULAR_COLLECTION_SUCCESS:
      return { ...state, loading: false, collectionsData: action.payload };
    case PARTICULAR_COLLECTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const selectCountryReducer = (state = {}, action) => {
  switch (action.type) {
    case SUCCESS_SELECT_COUNTRY_DATA:
      return { ...state, countryData: action.payload };
    case SELECT_COUNTRY_SUCCESS:
      return { ...state, selectedCountry: action.payload };
    case SELECT_COUNTRY_FAILUER:
      return { ...state, productErrorOnCountryChange: action.payload };
    default:
      return state;
  }
};

export const searchProductReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case PRODUCTS_SEARCH_REQUEST:
      return { loading: true };
    case PRODUCTS_SEARCH_SUCCESS:
      return { ...state, loading: false, filteredProducts: action.payload };
    case SAVE_SEARCH_PRODUCTS:
      return { ...state, loading: false, savedProducts: action.payload };
    case PRODUCTS_HEADER_SUCCESS:
      return { ...state, loading: false, pHeader: action.payload };
    case PRODUCTS_SEARCH_RESET:
      return { ...state, loading: false, filteredProducts: null };
    case PRODUCTS_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    case REFRESH_SEARCH_SCREEN:
      return { ...state, loading: false, refreshSearchScreen: Date.now() };
    default:
      return state;
  }
};

export const productDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCTS_DETAILS_REQUEST:
      return { loading: true };
    case PRODUCTS_DETAILS_SUCCESS:
      return { ...state, loading: false, productDetails: action.payload };
    case PRODUCTS_SIMILAR_SUCCESS:
      return { ...state, similarProducts: action.payload };
    case PRODUCTS_MATCHED_SUCCESS:
      return { ...state, matchedProducts: action.payload };
    case PRODUCTS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const footerDataReducer = async (state = { loading: true }, action) => {
  switch (action.type) {
    case FOOTER_DATA_REQUEST:
      return { loading: true };
    case FOOTER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        footerDetails: action.payload,
      };

    case FOOTER_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
