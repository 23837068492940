//Home Screen
export const HOMEPAGEDATA_SUCCESS = "HOMEPAGEDATA_SUCCESS";
export const HOMEPAGEDATA_REQUEST = "HOMEPAGEDATA_REQUEST";
export const HOMEPAGEDATA_FAIL = "HOMEPAGEDATA_FAIL";

export const CATEGORYDATA_SUCCESS = "CATEGORYDATA_SUCCESS";
export const CATEGORYDATA_REQUEST = "CATEGORYDATA_REQUEST";
export const CATEGORYDATA_FAIL = "CATEGORYDATA_FAIL";

export const ALL_COLLECTIONS_SUCCESS = "ALL_COLLECTIONS_SUCCESS";
export const ALL_COLLECTIONS_REQUEST = "ALL_COLLECTIONS_REQUEST";
export const ALL_COLLECTIONS_FAIL = "ALL_COLLECTIONS_FAIL";

export const PARTICULAR_COLLECTION_SUCCESS = "PARTICULAR_COLLECTION_SUCCESS";
export const PARTICULAR_COLLECTION_REQUEST = "PARTICULAR_COLLECTION_REQUEST";
export const PARTICULAR_COLLECTION_FAIL = "PARTICULAR_COLLECTION_FAIL";

export const REQUEST_SELECT_COUNTRY_DATA = "REQUEST_SELECT_COUNTRY_DATA";
export const SUCCESS_SELECT_COUNTRY_DATA = "SUCCESS_SELECT_COUNTRY_DATA";
export const FAIL_SELECT_COUNTRY_DATA = "FAIL_SELECT_COUNTRY_DATA";
export const SELECT_COUNTRY_SUCCESS = "SELECT_COUNTRY_SUCCESS";

export const PRODUCTS_SEARCH_SUCCESS = "PRODUCTS_SEARCH_SUCCESS";
export const PRODUCTS_SEARCH_REQUEST = "PRODUCTS_SEARCH_REQUEST";
export const SAVE_SEARCH_PRODUCTS = "SAVE_SEARCH_PRODUCTS";
export const PRODUCTS_HEADER_SUCCESS = "PRODUCTS_HEADER_SUCCESS";
export const PRODUCTS_SEARCH_RESET = "PRODUCTS_SEARCH_RESET";
export const PRODUCTS_SEARCH_FAIL = "PRODUCTS_SEARCH_FAIL";

export const PRODUCTS_DETAILS_SUCCESS = "PRODUCTS_DETAILS_SUCCESS";
export const PRODUCTS_DETAILS_REQUEST = "PRODUCTS_DETAILS_REQUEST";
export const PRODUCTS_DETAILS_FAIL = "PRODUCTS_DETAILS_FAIL";
export const PRODUCTS_SIMILAR_SUCCESS = "PRODUCTS_SIMILAR_SUCCESS";
export const PRODUCTS_MATCHED_SUCCESS = "PRODUCTS_MATCHED_SUCCESS";

export const FOOTER_DATA_SUCCESS = "FOOTER_DATA_SUCCESS";
export const FOOTER_DATA_REQUEST = "FOOTER_DATA_REQUEST";
export const FOOTER_DATA_FAIL = "FOOTER_DATA_FAIL";

export const CURRENCY_DATA_SUCCESS = "CURRENCY_DATA_SUCCESS";
export const CURRENCY_DATA_REQUEST = "CURRENCY_DATA_REQUEST";
export const CURRENCY_DATA_FAIL = "CURRENCY_DATA_FAIL";

export const REFRESH_SEARCH_SCREEN = "REFRESH_SEARCH_SCREEN";
export const SELECT_COUNTRY_FAILUER = "SELECT_COUNTRY_FAILUER";
