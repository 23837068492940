import Axios from "axios";
import {
  ALL_COLLECTIONS_FAIL,
  ALL_COLLECTIONS_REQUEST,
  ALL_COLLECTIONS_SUCCESS,
  CATEGORYDATA_FAIL,
  CATEGORYDATA_REQUEST,
  CATEGORYDATA_SUCCESS,
  CURRENCY_DATA_FAIL,
  CURRENCY_DATA_REQUEST,
  CURRENCY_DATA_SUCCESS,
  FOOTER_DATA_FAIL,
  FOOTER_DATA_REQUEST,
  FOOTER_DATA_SUCCESS,
  HOMEPAGEDATA_FAIL,
  HOMEPAGEDATA_REQUEST,
  HOMEPAGEDATA_SUCCESS,
  PARTICULAR_COLLECTION_FAIL,
  PARTICULAR_COLLECTION_REQUEST,
  PARTICULAR_COLLECTION_SUCCESS,
  PRODUCTS_DETAILS_FAIL,
  PRODUCTS_DETAILS_REQUEST,
  PRODUCTS_DETAILS_SUCCESS,
  PRODUCTS_HEADER_SUCCESS,
  PRODUCTS_MATCHED_SUCCESS,
  PRODUCTS_SEARCH_FAIL,
  PRODUCTS_SEARCH_REQUEST,
  PRODUCTS_SEARCH_SUCCESS,
  PRODUCTS_SIMILAR_SUCCESS,
  SAVE_SEARCH_PRODUCTS,
  SELECT_COUNTRY_FAILUER,
  SELECT_COUNTRY_SUCCESS,
  SUCCESS_SELECT_COUNTRY_DATA,
} from "../constants/productConstants";
import { setPremitives } from "../functions/commonFunctions";

export const currencyConverter = (from) => async (dispatch) => {
  dispatch({ type: CURRENCY_DATA_REQUEST });

  try {
    const { data } = await Axios.get(
      `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${from}.json`
    );
    dispatch({ type: CURRENCY_DATA_SUCCESS, payload: data[from] });
  } catch (err) {
    dispatch({ type: CURRENCY_DATA_FAIL, payload: err });
  }
};

function getCountry(getState) {
  return (
    getState().country.selectedCountry && getState().country.selectedCountry
  );
}

export const getHomePageData = () => async (dispatch, getState) => {
  dispatch({ type: HOMEPAGEDATA_REQUEST });
  const countryDetails = getCountry(getState);

  try {
    const { data } = await Axios.post(`/api/products/homepage`, {
      countryDetails,
    });
    dispatch({ type: HOMEPAGEDATA_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: HOMEPAGEDATA_FAIL, payload: err });
  }
};

export const getCategoryData = () => async (dispatch, getState) => {
  dispatch({ type: CATEGORYDATA_REQUEST });
  const countryDetails = getCountry(getState);

  try {
    const { data } = await Axios.post(`/api/products/category`, {
      countryDetails,
    });
    dispatch({ type: CATEGORYDATA_SUCCESS, payload: data.category });
  } catch (err) {
    dispatch({ type: CATEGORYDATA_FAIL, payload: err });
  }
};

export const getAllCollections =
  (searchParams) => async (dispatch, getState) => {
    dispatch({ type: ALL_COLLECTIONS_REQUEST });
    const countryDetails = getCountry(getState);
    try {
      const { data } = await Axios.post(`/api/products/all-collections`, {
        countryDetails,
        searchParams,
      });
      dispatch({ type: ALL_COLLECTIONS_SUCCESS, payload: data.products });
    } catch (err) {
      dispatch({ type: ALL_COLLECTIONS_FAIL, payload: err });
    }
  };

export const getParticularCollections =
  (collectionName, getState) => async (dispatch, getState) => {
    dispatch({ type: PARTICULAR_COLLECTION_REQUEST });
    const countryDetails = getCountry(getState);

    try {
      const { data } = await Axios.post(
        `/api/products/particular-collection/${collectionName}`,
        { countryDetails }
      );
      dispatch({ type: PARTICULAR_COLLECTION_SUCCESS, payload: data.products });
    } catch (err) {
      dispatch({ type: PARTICULAR_COLLECTION_FAIL, payload: err });
    }
  };

export const selectCountry =
  (country, productDetails, navigate) => async (dispatch) => {
    if (productDetails) {
      // api call
      const { data } = await Axios.post(
        "/api/products/check-product-on-country-change",
        { productDetails, country }
      );
      if (data.msg === "success") {
        await dispatch({ type: SELECT_COUNTRY_SUCCESS, payload: country });
        localStorage.setItem(
          "country",
          JSON.stringify({ selectedCountry: country })
        );
        setTimeout(() => {
          navigate(
            `/product-details/${data.product.Product_Code}?reload=${Date.now()}`
          );
        }, 300);
      } else {
        dispatch({
          type: SELECT_COUNTRY_FAILUER,
          payload: "Product is not available in selected country",
        });
      }
    } else {
      dispatch({ type: SELECT_COUNTRY_SUCCESS, payload: country });
      localStorage.setItem(
        "country",
        JSON.stringify({ selectedCountry: country })
      );
      window.location.href = window.location.origin;
    }
  };

export const getCountryData = () => async (dispatch) => {
  try {
    const { data } = await Axios.get("/api/products/country-data");
    dispatch({ type: SUCCESS_SELECT_COUNTRY_DATA, payload: data });
  } catch (err) {
    console.log(err);
  }
};

export const productsSearch = (searchV) => async (dispatch, getState) => {
  const searchValue = searchV ? searchV : [];

  dispatch({ type: PRODUCTS_SEARCH_REQUEST });
  const countryDetails = getCountry(getState);

  try {
    const { data } = await Axios.post(
      `/api/products/search?search=${searchValue}`,
      { countryDetails }
    );

    dispatch({ type: PRODUCTS_HEADER_SUCCESS, payload: data.pHeader });
    dispatch({ type: PRODUCTS_SEARCH_SUCCESS, payload: data.products });
    dispatch({ type: SAVE_SEARCH_PRODUCTS, payload: data.products });
  } catch (err) {
    dispatch({ type: PRODUCTS_SEARCH_FAIL, payload: err });
  }
};

export const productsDeatilsFunc =
  (productcode, color = undefined) =>
  async (dispatch, getState) => {
    dispatch({ type: PRODUCTS_DETAILS_REQUEST });
    const cartItems = getState().cart.cartItems;
    const countryDetails = getCountry(getState);

    try {
      var d = new Date();
      const { data } = await Axios.post(
        `/api/products/deatils/${productcode}`,
        { countryDetails }
      );

      dispatch({
        type: PRODUCTS_DETAILS_SUCCESS,
        payload: await setPremitives(data.product, color, cartItems),
      });
      dispatch({
        type: PRODUCTS_SIMILAR_SUCCESS,
        payload: data.similarProducts,
      });
      dispatch({
        type: PRODUCTS_MATCHED_SUCCESS,
        payload: data.matchedProducts,
      });
      d = new Date();
    } catch (err) {
      dispatch({ type: PRODUCTS_DETAILS_FAIL, payload: err });
    }
  };

export const getFooterData = () => async (dispatch) => {
  const { data } = await Axios.get(`/api/products/footerdetails`);
  localStorage.setItem("footerDetails", JSON.stringify(data));
};
