import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import "./css/collection.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { GoogleOAuthProvider } from "@react-oauth/google";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
const clientID =
  "605949869272-pe1arqodbg5sq9tposlce78f3ij42m74.apps.googleusercontent.com";

if (rootElement.hasChildNodes()) {
  root.hydrate(
    <Provider store={store}>
      <React.StrictMode>
        <HelmetProvider>
          <GoogleOAuthProvider clientId={clientID}>
            <App />
          </GoogleOAuthProvider>
        </HelmetProvider>
      </React.StrictMode>
    </Provider>
  );
} else {
  root.render(
    <Provider store={store}>
      <React.StrictMode>
        <HelmetProvider>
          <GoogleOAuthProvider clientId={clientID}>
            <App />
          </GoogleOAuthProvider>
        </HelmetProvider>
      </React.StrictMode>
    </Provider>
  );
}

reportWebVitals();
