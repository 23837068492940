export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_UPDATE_ITEM = "CART_UPDATE_ITEM";
export const SAVE_BUY_NOW_ITEM = "SAVE_BUY_NOW_ITEM";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const CART_SAVE_SHIPPING_ADDRESS = "CART_SAVE_SHIPPING_ADDRESS";
export const CART_SAVE_BILLING_ADDRESS = "CART_SAVE_BILLING_ADDRESS";
export const CART_SAVE_PAYMENT_METHOD = "CART_SAVE_PAYMENT_METHOD";
export const CART_EMPTY = "CART_EMPTY";
export const SAVE_DEAL_OF_THE_DAY = "SAVE_DEAL_OF_THE_DAY";
export const REMOVE_DEAL_OF_THE_DAY = "REMOVE_DEAL_OF_THE_DAY";
export const CART_DELIVERY_SLOT = "CART_DELIVERY_SLOT";
export const REMOVE_SAVE_BUY_NOW = "REMOVE_SAVE_BUY_NOW";
export const REPLACE_CART_ITEMS = "REPLACE_CART_ITEMS";

export const WISHLIST_ADD_ITEM = "WISHLIST_ADD_ITEM";
export const WISHLIST_REMOVE_ITEM = "WISHLIST_REMOVE_ITEM";
