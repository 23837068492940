import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Footer from "./templates/Footer";
import NavBar from "./templates/homepage/navBar/NavBar";
import Loading from "./templates/loaders/Loading";
import FloatingMenuBar from "./templates/headers/FloatingMenuBar";
import OurProducts from "./templates/ourProducts/OurProducts";
import { IoLogoWhatsapp } from "react-icons/io";

const HomeScreen = lazy(() => import("./screens/HomeScreen"));
const ByadabBlogs = lazy(() => import("./templates/blogs/ByadabBlogs"));
const NewProduct = lazy(() => import("./screens/AddProductScreen/NewProduct"));
const HeaderBottom = lazy(() => import("./templates/headers/HeaderBottom"));
const HeaderTopSlider = lazy(() =>
  import("./templates/headers/HeaderTopSlider")
);
const SelectCountry = lazy(() => import("./templates/headers/SelectCountry"));

const AllCollection = lazy(() => import("./screens/collections/AllCollection"));
const ParticularCollection = lazy(() =>
  import("./screens/collections/ParticularCollection")
);

const SearchScreen = lazy(() => import("./screens/search/SearchScreen"));
const ProductScreen = lazy(() => import("./screens/search/ProductScreen"));
const CartScreen = lazy(() => import("./screens/cart/CartScreen"));

const OrderDetails = lazy(() => import("./screens/orders/OrderDetails"));
const OrderHistory = lazy(() => import("./screens/orders/OrderHistory"));

const SignIn = lazy(() => import("./screens/signInUp/SignIn"));
const LoginWithOTP = lazy(() => import("./screens/signInUp/LoginWithOTP"));
const SignUp = lazy(() => import("./screens/signInUp/SignUp"));

const Accounts = lazy(() => import("./screens/accounts/Accounts"));

const WhyBYADAB = lazy(() => import("./templates/footer/byadab/WhyByADAB"));
const ContactUs = lazy(() => import("./templates/footer/byadab/ContactUs"));
const ShippingDelivery = lazy(() =>
  import("./templates/footer/assistance/ShippingDelivery")
);
const RetrunExchange = lazy(() =>
  import("./templates/footer/assistance/RetrunExchange")
);
const PrivacyPolicy = lazy(() =>
  import("./templates/footer/assistance/PrivacyPolicy")
);
const InternationalShipping = lazy(() =>
  import("./templates/footer/assistance/InternationalShipping")
);

const HotelCollection = lazy(() =>
  import("./templates/footer/services/HotelCollection")
);
const CorporateOrder = lazy(() =>
  import("./templates/footer/services/CorporateOrder")
);
const FitToBed = lazy(() => import("./templates/footer/services/FitToBed"));
const WeddingShop = lazy(() =>
  import("./templates/footer/services/WeddingShop")
);

const CareInstructions = lazy(() =>
  import("./templates/footer/andMore/CareInstructions")
);
const Fabrics = lazy(() => import("./templates/footer/andMore/Fabrics"));
const Franchise = lazy(() => import("./templates/footer/andMore/Franchise"));
const SizeGuide = lazy(() => import("./templates/footer/andMore/SizeGuide"));
const Faqs = lazy(() => import("./templates/footer/andMore/Faqs"));
const Career = lazy(() => import("./templates/footer/andMore/Career"));
const Partners = lazy(() => import("./templates/footer/andMore/Partners"));

function App() {
  const country = useSelector((state) => state.country);
  const { selectedCountry } = country;

  const homePage = useSelector((state) => state.homePage);
  const { homePageData } = homePage;

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading name="BoxLoading" />}>
        <HeaderTopSlider />
        {(!selectedCountry ||
          (selectedCountry &&
            homePageData &&
            homePageData.Our_Brands &&
            homePageData.Our_Brands.length === 0)) && <SelectCountry />}

        <HeaderBottom />

        <FloatingMenuBar />

        <NavBar border={true} />

        {window.innerWidth > 576 ? (
          <div
            style={{
              position: "fixed",
              bottom: 30,
              right: 50,
              zIndex: 1000000,
            }}
          >
            <Link to="https://wa.me/917827195006" target="_blank">
              <IoLogoWhatsapp fontSize={50} color="#24D366" />
            </Link>
          </div>
        ) : (
          ""
        )}

        <main>
          <Routes>
            <Route path="/" element={<HomeScreen />} exact></Route>
            <Route
              path="/all-collections/:collectionName/:search"
              element={<AllCollection />}
            ></Route>
            <Route
              path="/collection/:collectionName"
              element={<ParticularCollection />}
            ></Route>
            <Route path="/NewProduct" element={<NewProduct />}></Route>
            <Route path="/products" element={<SearchScreen />}></Route>
            <Route
              path="/product-details/:productcode"
              element={<ProductScreen />}
            ></Route>
            <Route path="/our-products" element={<OurProducts />}></Route>
            <Route path="/blogs" element={<ByadabBlogs />}></Route>
            <Route path="/blogs/:blogId" element={<ByadabBlogs />}></Route>

            <Route
              path="/cart"
              element={<CartScreen showNavBar={true} />}
            ></Route>

            <Route path="/order/:orderId" element={<OrderDetails />}></Route>
            <Route
              path="/orderhistory"
              element={<OrderHistory showNavBar={true} />}
            ></Route>

            <Route path="/sign-in" element={<LoginWithOTP />}></Route>
            <Route path="/log-in-with-password" element={<SignIn />}></Route>
            <Route path="/sign-up" element={<SignUp />}></Route>

            <Route path="/accounts/:id" element={<Accounts />}></Route>

            <Route path="/why-by-adab" element={<WhyBYADAB />}></Route>
            <Route path="/contact-us" element={<ContactUs />}></Route>
            <Route
              path="/shipping-delivery"
              element={<ShippingDelivery />}
            ></Route>
            <Route path="/return-exchange" element={<RetrunExchange />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route
              path="/hotel-collections"
              element={<HotelCollection />}
            ></Route>
            <Route
              path="/corporate-orders"
              element={<CorporateOrder />}
            ></Route>
            <Route path="/fit-to-bed" element={<FitToBed />}></Route>
            <Route path="/wedding-shop" element={<WeddingShop />}></Route>
            <Route
              path="/international-shipping"
              element={<InternationalShipping />}
            ></Route>
            <Route path="/fabrics" element={<Fabrics />}></Route>
            <Route path="/size-guide" element={<SizeGuide />}></Route>
            <Route
              path="/care-instructions"
              element={<CareInstructions />}
            ></Route>
            <Route path="/faqs" element={<Faqs />}></Route>
            <Route path="/franchise" element={<Franchise />}></Route>
            <Route path="/career" element={<Career />}></Route>
            <Route path="/our-partners" element={<Partners />}></Route>
          </Routes>
        </main>

        <Footer />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
