import React from "react";
import { useDispatch } from "react-redux";
import {
  PRODUCTS_SEARCH_RESET,
  PRODUCTS_SEARCH_SUCCESS,
  REFRESH_SEARCH_SCREEN,
} from "../../constants/productConstants";
import { MdClose } from "react-icons/md";

export default function SortBar(props) {
  const SortArray = [
    "Best Selling",
    "Lowest Price",
    "Highest Price",
    "Newest First",
    "Oldest First",
  ];
  let filteredProducts = props.savedProducts,
    pHeader = props.pHeader;

  const dispatch = useDispatch();

  function sortBy(x) {
    props.close();

    switch (x) {
      case "Lowest Price":
        sortByPrice(true);
        break;
      case "Highest Price":
        sortByPrice(false);
        break;
      case "Newest First":
        sortByTime(false);
        break;
      case "Oldest First":
        sortByTime(true);
        break;
      default:
        dispatch({
          type: PRODUCTS_SEARCH_SUCCESS,
          payload: sortByBestSelling(),
        });
    }
  }

  function sortByBestSelling() {
    // let sortedArray = [];
    // filteredProducts.forEach((product) => {
    //   if (product?.Best_Sellers) sortedArray.unshift(product);
    //   else sortedArray.push(product);
    // });
    let sortedArray = filteredProducts.filter((x) => {
      if (x.Best_Sellers === "yes") {
        console.log(x);
        return true;
      }
    });

    return sortedArray;
  }

  function sortByPrice(lowToHigh) {
    const sortedArray = filteredProducts.sort((a, b) => {
      if (lowToHigh) {
        let fa = a.finalPricerRange.min,
          fb = b.finalPricerRange.min;

        if (fa < fb) return -1;
        else if (fa > fb) return 1;
        else return 0;
      } else {
        let fa = a.finalPricerRange.max,
          fb = b.finalPricerRange.max;

        if (fb < fa) return -1;
        else if (fb > fa) return 1;
        else return 0;
      }

      return 0;
    });

    // dispatch({ type: PRODUCTS_SEARCH_RESET, payload: sortedArray });
    dispatch({ type: PRODUCTS_SEARCH_SUCCESS, payload: sortedArray });
    dispatch({
      type: REFRESH_SEARCH_SCREEN,
    });
  }

  function sortByTime(lowToHigh) {
    const sortedArray = filteredProducts.sort((a, b) => {
      let fa = new Date(a.TimeStamp),
        fb = new Date(b.TimeStamp);
      fa = fa.getTime();
      fb = fb.getTime();

      if (lowToHigh) {
        if (fa < fb) return -1;
        else if (fa > fb) return 1;
        else return 0;
      } else {
        if (fb < fa) return -1;
        else if (fb > fa) return 1;
        else return 0;
      }

      return 0;
    });

    dispatch({ type: PRODUCTS_SEARCH_SUCCESS, payload: sortedArray });
    dispatch({
      type: REFRESH_SEARCH_SCREEN,
    });
  }

  return (
    <div>
      {window.innerWidth > 576 ? (
        <div style={{ position: "relative" }}>
          <div className={props.sortBarClass}>
            <ul className="pointer">
              {SortArray.map((x, i) => (
                <li onClick={() => sortBy(x)} key={i}>
                  {x}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <div
          className={
            props.showSortBar
              ? "absoluteClass mobile"
              : "minimiseDivClass mobile"
          }
        >
          <div
            className={props.sortBarClass}
            style={{
              border: "1px solid silver",
              borderTopLeftRadius: "2rem",
              borderTopRightRadius: "2rem",
            }}
          >
            <div className="mainSortDiv">
              <div
                style={{
                  float: "right",
                  marginRight: "1rem",
                  marginTop: "10px",
                }}
                onClick={() => props.close()}
              >
                <MdClose fontSize={20} />
              </div>
              <ul>
                {SortArray.map((x, i) => (
                  <li onClick={() => sortBy(x)} key={i}>
                    {x}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
