import {
  CART_ADD_ITEM,
  CART_DELIVERY_SLOT,
  CART_EMPTY,
  CART_REMOVE_ITEM,
  CART_SAVE_BILLING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
  REMOVE_SAVE_BUY_NOW,
  REPLACE_CART_ITEMS,
  SAVE_BUY_NOW_ITEM,
  WISHLIST_ADD_ITEM,
  WISHLIST_REMOVE_ITEM,
} from "../constants/cartConstants";

export const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      let item = action.payload,
        cartItems = state.cartItems;
      let upadateItemIndex = -1,
        i = 0;

      for (i = 0; i < cartItems.length; i++) {
        let prod = cartItems[i];
        if (
          item.Product_Code === prod.Product_Code &&
          item.colour === prod.colour &&
          item.size === prod.size &&
          item.imageSrc === prod.imageSrc &&
          item.height === prod.height &&
          item.gsm === prod.gsm &&
          item.thickness === prod.thickness
        ) {
          upadateItemIndex = i;
          break;
        }
      }

      if (upadateItemIndex != -1) {
        cartItems[i] = item;
        return {
          ...state,
          cartItems: cartItems,
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      }
    case REPLACE_CART_ITEMS:
      return { ...state, cartItems: action.payload };
    case SAVE_BUY_NOW_ITEM:
      return { ...state, buyNowItem: [action.payload] };
    case CART_REMOVE_ITEM:
      let item2 = action.payload;
      return {
        ...state,
        cartItems: state.cartItems.filter((prod) => prod.id !== item2.id),
      };
    case CART_SAVE_SHIPPING_ADDRESS:
      return { ...state, shippingAddress: action.payload };
    case CART_SAVE_BILLING_ADDRESS:
      return { ...state, billingAddress: action.payload };
    case CART_SAVE_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload };
    case CART_DELIVERY_SLOT:
      return { ...state, deliverySlot: action.payload };
    case CART_EMPTY:
      return { ...state, cartItems: [] };
    case REMOVE_SAVE_BUY_NOW:
      return { ...state, buyNowItem: undefined };
    default:
      return state;
  }
};

export const wishlistReducer = (state = { wishlistItems: [] }, action) => {
  switch (action.type) {
    case WISHLIST_ADD_ITEM:
      const item = action.payload;
      if (state.wishlistItems)
        return {
          ...state,
          wishlistItems: [...state.wishlistItems, item],
        };
      else
        return {
          ...state,
          wishlistItems: [item],
        };
    case WISHLIST_REMOVE_ITEM:
      return {
        ...state,
        wishlistItems: state.wishlistItems.filter(
          (x) => x.Product_Code !== action.payload
        ),
      };
    default:
      return state;
  }
};
