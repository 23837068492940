import React from "react";
import { useNavigate } from "react-router-dom";
import { PRODUCTS_SEARCH_RESET } from "../../../constants/productConstants";
import { useDispatch } from "react-redux";

export default function NavBarElementForCollection(props) {
  const navigate = useNavigate(),
    dispatch = useDispatch();

  function SearchHandlerCategory(search) {
    let newSearch = search;
    props.handleMouseOut("");

    dispatch({ type: PRODUCTS_SEARCH_RESET });
    navigate(`/all-collections/${newSearch}/all`);
  }

  function SearchHandler(search) {
    props.handleMouseOut("");

    dispatch({ type: PRODUCTS_SEARCH_RESET });
    navigate(`/collection/${search.replaceAll(" ", "-")}`);
  }

  function clickHandler(x) {
    props.handleMouseOut("");

    if (x?.Category === "Bedding") {
      navigate(`/all-collections/${x?.Category}/all`);
    } else if (x?.Category === "Bath Linen") {
      navigate(`/all-collections/${x?.Category.replace(" ", "-")}/all`);
    } else SearchHandler(props?.name?.toLowerCase());
  }

  return (
    <div className="navbarShowcollection">
      {props.collectionsData?.map((x, i) => (
        <div key={i}>
          <p
            onClick={() => SearchHandlerCategory(x.Category.replace(" ", "-"))}
            style={{ fontWeight: "600" }}
          >
            {x.Category}
          </p>
          {x.Our_Brands_Data?.map((y, j) => (
            <p
              key={j}
              onClick={() => SearchHandler(y.Our_Brands)}
              style={{ marginLeft: "10px" }}
            >
              {y.Our_Brands}
            </p>
          ))}
          <p style={{ marginLeft: "10px" }} onClick={() => clickHandler(x)}>
            View All
          </p>
        </div>
      ))}
    </div>
  );
}
