import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productsSearch } from "../../actions/productActions";
import Loading from "../../templates/loaders/Loading";
import ProductView from "../../templates/products/ProductView";
import SortBar from "../../templates/products/SortBar";
import InfiniteScroll from "react-infinite-scroll-component";
import { TfiLayoutGrid2Alt } from "react-icons/tfi";
import { BiSolidGrid } from "react-icons/bi";

const OurProducts = () => {
  const dispatch = useDispatch();
  const [gridState, setGridState] = useState(
    window.innerWidth < 576 ? "grid-col-1" : "gird-col-3"
  );
  const [active, setActive] = useState(false);
  const [current, setCurrent] = useState([]);

  const queryParams = new URLSearchParams(window.location.search);
  const searchValue = queryParams.get("search");

  const searchProducts = useSelector((state) => state.searchProducts);
  const {
    loading,
    error,
    filteredProducts,
    pHeader,
    savedProducts,
    refreshSearchScreen,
  } = searchProducts;

  const [vars, setVars] = useState({
    showFilterSideBar: false,
    filterSideBarClass:
      window.innerWidth < 576
        ? "filterDiv col-1 hide-side-bar"
        : "filterDiv col-1 desktopAndtablet",
    showSortBar: false,
    sortBarClass:
      window.innerWidth < 576
        ? "sortDiv hide-bottom-bar"
        : "hideMainSortDivdesktop",
  });

  useEffect(() => {
    if (!filteredProducts) dispatch(productsSearch("bedding"));
  }, [dispatch, searchValue, window.location.search]);
  useEffect(() => {
    if (filteredProducts) setCurrent(filteredProducts.slice(0, 20));
  }, [filteredProducts, refreshSearchScreen]);
  useEffect(() => {
    setGridState(window.innerWidth < 576 ? "grid-col-1" : "grid-col-3");
  }, []);

  function getMoreData() {
    setCurrent(
      current.concat(
        filteredProducts.slice(current.length, current.length + 20)
      )
    );
  }

  function showFilterSideBar() {
    if (window.innerWidth < 576) {
      setVars({
        ...vars,
        showFilterSideBar: true,
        filterSideBarClass: "filterDiv col-1 show-side-bar",
      });
    } else
      setVars({
        ...vars,
        showFilterSideBar: false,
        filterSideBarClass: "filterDiv col-1 desktopAndtablet",
      });
  }

  function closeFilterSideBar() {
    setVars({
      ...vars,
      showFilterSideBar: false,
      filterSideBarClass: "filterDiv col-1 hide-side-bar",
    });
  }

  function showSortBar() {
    setActive(!active);
    if (window.innerWidth < 576) {
      setVars({
        ...vars,
        showSortBar: true,
        sortBarClass: "sortDiv show-bottom-bar",
      });
    } else
      setVars({
        ...vars,
        showSortBar: true,
        sortBarClass: "mainSortDivdesktop",
      });
  }

  function closeSortBar() {
    setActive(!active);
    if (window.innerWidth < 576)
      setVars({
        ...vars,
        showSortBar: false,
        sortBarClass: "sortDiv hide-bottom-bar",
      });
    else
      setVars({
        ...vars,
        showSortBar: false,
        sortBarClass: "hideMainSortDivdesktop",
      });
  }
  function handleGrid2() {
    setGridState("grid-col-2");
  }
  function handleGrid3() {
    setGridState("grid-col-3");
  }

  return (
    <div>
      <div className="serachScreen row1">
        <div className="col-2" style={{ width: "100%" }}>
          {loading && <Loading name="BoxLoading" />}
          {error ? (
            { error }
          ) : (
            <div>
              <div className="row1 center" style={{ flexDirection: "column" }}>
                <p className="searchHeading" style={{ fontSize: "28px" }}>
                  Our Products
                </p>
                <p
                  style={{
                    fontFamily: "Sans-serif",
                    marginTop: ".6rem",
                    fontSize: "1rem",
                    opacity: "0.6",
                  }}
                >
                  {filteredProducts && `${filteredProducts.length} items`}
                </p>
              </div>
              <div className="row1 center" style={{ marginTop: "0.5rem" }}>
                {/* <Typography
                  fontFamily={"Raleway"}
                  fontSize={"1.3rem"}
                  sx={{ mt: 1, px: 10 }}
                >
                  The newest additions to our collection of custom-made Designs,
                  curated just for you.
                </Typography> */}
              </div>

              <div
                className="row1 center"
                style={{
                  width: "170%",
                  marginTop: "-30px",
                  alignItems: "center",
                }}
              >
                <button
                  id="grid2"
                  className="hoverGrid"
                  style={{
                    border: "none",
                    fontSize: "1.5rem",
                    marginBottom: "2px",
                  }}
                  onClick={handleGrid2}
                >
                  <TfiLayoutGrid2Alt />
                </button>
                <button
                  id="grid3"
                  className=""
                  style={{
                    border: "none",
                    background: "none",
                    marginLeft: "1rem",
                    fontSize: "2.5rem",
                  }}
                  onClick={handleGrid3}
                >
                  <BiSolidGrid />
                </button>
                <div
                  style={{
                    borderLeft: "2px solid black",
                    height: "50px",
                    marginLeft: "1rem",
                    marginTop: "-5px",
                  }}
                ></div>
                <div
                  className="row1 center"
                  style={{ marginLeft: "1rem", marginBottom: "10px" }}
                >
                  <strong
                    className="row1 pointer"
                    style={{ alignItems: "flex-end" }}
                    onClick={() => showSortBar()}
                  >
                    Sort{" "}
                    <i
                      className="fa-solid fa-angle-down"
                      style={{ marginLeft: "5px" }}
                    ></i>
                  </strong>

                  {active ? (
                    <div>
                      <SortBar
                        sortBarClass={vars.sortBarClass}
                        showSortBar={vars.showSortBar}
                        close={closeSortBar}
                        filteredProducts={filteredProducts}
                        pHeader={pHeader}
                        savedProducts={savedProducts}
                      ></SortBar>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {filteredProducts && (
                <InfiniteScroll
                  dataLength={current.length}
                  next={getMoreData}
                  hasMore={filteredProducts.length > current.length}
                  loader={<h4>Loading...</h4>}
                >
                  <div className={`${gridState}`}>
                    {current.map((x, i) => (
                      <ProductView product={x} key={i} fullWidth={true} />
                    ))}
                  </div>
                </InfiniteScroll>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OurProducts;
