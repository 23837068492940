import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SelectCountry from "./SelectCountry";
import SearchBar from "./SearchBar";
import { SwipeableDrawer } from "@mui/material";
import { FaHome } from "react-icons/fa";
import { FaHeadphonesAlt } from "react-icons/fa";

const FloatingMenuBar = () => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState("home");
  const navigate = useNavigate();

  const { selectedCountry } = useSelector((state) => state.country);

  function handleHome() {
    navigate("/");
    setActive("home");
  }
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  function close() {
    setState({ ...state, ["right"]: false });
  }

  function openHandler() {
    setState({ ...state, ["right"]: true });
  }

  return (
    <div className="floatingMenu displayNone">
      {open && <SelectCountry open={true} />}
      <div
        className="row1"
        style={{ justifyContent: "space-evenly", marginTop: "4px" }}
      >
        {/* Home */}

        <div
          className={`floatingMenuIcon ${
            active === "home" ? "homeHover" : "menuOpacity"
          }  floatingFlex`}
          onClick={() => handleHome()}
        >
          <FaHome style={{ fontSize: "1.3rem" }} />
          <p>Home</p>
        </div>

        {/* search */}

        <div>
          <React.Fragment>
            <SwipeableDrawer
              anchor={"top"}
              open={state["right"]}
              onClose={() => close()}
              onOpen={() => openHandler()}
              BackdropProps={{ invisible: !state.top }}
            >
              <div
                className="row1 center"
                style={{ width: "100vw", marginTop: "10px" }}
              >
                <i
                  className="fa fa-arrow-left  fa-2x"
                  aria-hidden="true"
                  onClick={close}
                  style={{ marginRight: "5px" }}
                ></i>

                <SearchBar mobile={true} close={close}></SearchBar>
              </div>
            </SwipeableDrawer>
            <div
              className={`floatingMenuIcon ${
                active === "search" ? "homeHover" : "menuOpacity"
              }  floatingFlex`}
              onClick={() => {
                openHandler();
                setActive("search");
              }}
            >
              <img
                src="https://awsbyadab.s3.amazonaws.com/uploads/one-time-used-images/H0cmMyjsR7yD3A9r"
                alt="logo"
                style={{ width: "1.2rem" }}
              ></img>
              <p>Search</p>
            </div>
          </React.Fragment>
        </div>

        {/* headPhone */}
        <div
          className={`floatingMenuIcon ${
            active === "headPhone" ? "homeHover" : "menuOpacity"
          }  floatingFlex`}
          onClick={() => setActive("headPhone")}
        >
          <Link to="tel:+919818133733">
            <FaHeadphonesAlt
              style={{ fontSize: "1.3rem", marginLeft: "4px" }}
            />

            <p>Stylist</p>
          </Link>
        </div>

        {/* Profile */}
        <div
          className={`floatingMenuIcon ${
            active === "Profile" ? "homeHover" : "menuOpacity"
          }  floatingFlex`}
          onClick={() => {
            navigate("/accounts/personal details");
            setActive("Profile");
          }}
        >
          <img
            src="https://awsbyadab.s3.amazonaws.com/uploads/one-time-used-images/Y1ZKP7RctDUfmlJw"
            alt="logo"
            style={{ width: "1.2rem" }}
          ></img>
          <p>Profile</p>
        </div>

        {/* Flag */}
        <div
          className={`floatingMenuIcon ${
            active === "Flag" ? "homeHover" : ""
          }  floatingFlex`}
        >
          <img
            onClick={() => {
              setActive("Flag");
              setOpen(!open);
            }}
            style={{
              cursor: "pointer",
              width: "1.3rem",
            }}
            src={`https://flags.fmcdn.net/data/flags/mini/${
              selectedCountry
                ? selectedCountry.Country_codes.toLowerCase()
                : "in"
            }.png`}
          ></img>
          <p style={{ marginTop: "14px" }}>Country</p>
        </div>
      </div>
    </div>
  );
};

export default FloatingMenuBar;
