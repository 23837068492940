import { REMOVE_USER_DETAILS, USER_DETAILS_SUCCESS } from "../constants/userConstants";

export const userDetailsReducer = (state = null, action) => {
    switch (action.type) {
        case USER_DETAILS_SUCCESS:
            return { ...state, userInfo: action.payload };
        case REMOVE_USER_DETAILS:
            return { ...state, userInfo: action.payload }
        default:
            return state;
    }
}