import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function ColourSlider(props) {
  const [imageArr, setImageArr] = useState(props.imageArr);

  useEffect(() => {
    if (props.imageArr[0]) {
      props.imageArr[0].border = true;
      setImageArr(props.imageArr);
    }
  }, [props]);
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <img
        src="https://awsbyadab.s3.amazonaws.com/uploads/one-time-used-images/9u0t5vUmpJbJsCYc"
        alt=""
        onClick={() => onClick()}
        className={className}
        style={{ ...style, display: "block" }}
      ></img>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <img
        src="https://awsbyadab.s3.amazonaws.com/uploads/one-time-used-images/EeqD8PssIxq8c1zU"
        alt=""
        onClick={() => onClick()}
        className={className}
        style={{ ...style, display: "block" }}
      ></img>
    );
  };

  function getLenOfImageArr() {
    let len = imageArr.length;
    if (len > 4) {
      return 4;
    } else if (len <= 4 && len > 0) {
      return len;
    }
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: getLenOfImageArr(),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };

  // const [vars, setVars] = React.useState({ index: 0 })

  function setImage(img, i) {
    document.getElementById(`image${props.product.Product_Code}`).src =
      imageArr[i].link;
    props.product.selectedUniqueImage = imageArr[i].colour;

    let temp = [];
    imageArr.forEach((y) => {
      if (y.colour === img.colour) {
        temp.push({ ...y, border: true });
      } else {
        delete y.border;
        temp.push(y);
      }
    });
    setImageArr(temp);
  }

  return (
    <div
      className="colorSliderContainer"
      style={{ textAlign: "center", width: "250px" }}
    >
      <Slider {...settings}>
        {imageArr &&
          imageArr.map(
            (img, i) =>
              img && (
                <div key={i} className="imgBox pointer">
                  <LazyLoadImage
                    id={`colourImage${props.product.Product_Code}${i}`}
                    className={img?.border ? "box" : ""}
                    src={img?.link}
                    alt={props?.product?.Group_Products}
                    effect="blur"
                    key={i}
                    onClick={() => setImage(img, i)}
                  ></LazyLoadImage>
                </div>
              )
          )}
      </Slider>
    </div>
  );
}
