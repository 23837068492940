import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PRODUCTS_SEARCH_RESET } from "../../constants/productConstants";

export default function SearchBar(props) {
  const [search, setSearch] = React.useState("");
  const placeholder = props.placeholder;
  const blogWidth = props.width;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function submitHandler(e) {
    e.preventDefault();

    dispatch({ type: PRODUCTS_SEARCH_RESET });
    navigate(`/products?search=${search.replace(/\s+/g, "-")}`);

    if (props.close) props.close();
    setSearch("");
  }

  function submitForm() {
    dispatch({ type: PRODUCTS_SEARCH_RESET });
    navigate(`/products?search=${search.replace(/\s+/g, "-")}`);

    if (props.close) props.close();
  }

  return (
    <form
      onSubmit={submitHandler}
      id="search-Form"
      className="flex justify-center mx-2"
    >
      <div
        className={props.mobile ? "row1 searchboxMobile" : "row1 searchBox"}
        style={{ width: blogWidth }}
      >
        <input
          className="input"
          placeholder={placeholder ? placeholder : "Search..."}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: "100%", background: "transparent" }}
        ></input>
        <img
          src="https://awsbyadab.s3.amazonaws.com/uploads/one-time-used-images/eB83U30x2GPQimOA"
          alt="search"
          style={{ width: "2rem" }}
          onClick={() => submitForm()}
        ></img>
      </div>
    </form>
  );
}
