import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currencyConverter } from "../actions/productActions";

export default function Price(props) {
  let productDetails = props.productDetails;

  const [output, setOutput] = useState(0);
  const [rangePrice, setRangePrice] = useState({ min: 0, max: 0 });

  const currencyData = useSelector((state) => state.currencyData);
  const { currencies } = currencyData;

  const country = useSelector((state) => state.country);
  const { selectedCountry } = country;
  //   console.log(currencies, selectedCountry);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(currencyConverter("inr"));
  }, ["inr"]);

  useEffect(() => {
    if (currencies)
      if (productDetails.final_price) convert();
      else if (productDetails.finalPricerRange) convertRange();
      else calculateFinalPriceRange();
  }, [currencies, productDetails]);

  // Function to convert the currency
  function convert() {
    var rate =
      currencies[
        selectedCountry && selectedCountry.Currency_codes
          ? selectedCountry.Currency_codes.toLowerCase()
          : "inr"
      ];
    // console.log(selectedCountry, rate, "convert");

    setOutput(
      productDetails.final_price
        ? priceConverter(
            productDetails.final_price,
            rate,
            selectedCountry,
            productDetails.Packed_Product_Weight
          )
        : 0
    );
  }

  function convertRange() {
    // console.log(productDetails.finalPricerRange);
    var rate =
      currencies[
        selectedCountry && selectedCountry.Currency_codes
          ? selectedCountry.Currency_codes.toLowerCase()
          : "inr"
      ];

    // console.log(selectedCountry, currencies, rate, "convertRange");

    setRangePrice({
      ...rangePrice,
      min: productDetails.finalPricerRange
        ? priceConverter(
            productDetails.finalPricerRange.min,
            rate,
            selectedCountry,
            productDetails.finalPricerRange.maxWeight
          )
        : 0,
      max: productDetails.finalPricerRange
        ? priceConverter(
            productDetails.finalPricerRange.max,
            rate,
            selectedCountry,
            productDetails.finalPricerRange.maxWeight
          )
        : 0,
    });
  }

  function calculateFinalPriceRange() {
    let finalPricerRange = {
      min: undefined,
      max: 0,
    };

    productDetails.variations &&
      productDetails.variations.forEach((x) => {
        if (!finalPricerRange.min) finalPricerRange.min = x.Final_Price;
        if (x.Final_Price < finalPricerRange.min)
          finalPricerRange.min = x.Final_Price;
        if (x.Final_Price > finalPricerRange.max)
          finalPricerRange.max = x.Final_Price;
      });

    var rate =
      currencies[
        selectedCountry && selectedCountry.Currency_codes
          ? selectedCountry.Currency_codes.toLowerCase()
          : "inr"
      ];

    // console.log(rate, "calculateFinalPriceRange");

    setRangePrice({
      ...rangePrice,
      min: finalPricerRange
        ? priceConverter(
            finalPricerRange.min,
            rate,
            selectedCountry,
            productDetails.Packed_Product_Weight
          )
        : 0,
      max: finalPricerRange
        ? priceConverter(
            finalPricerRange.max,
            rate,
            selectedCountry,
            finalPricerRange.Packed_Product_Weight
          )
        : 0,
    });
  }

  return (
    <div
      style={{
        fontFamily: "poppins, sans-serif",
        opacity: "1",

        fontWeight: "500",
      }}
    >
      PRICE :{" "}
      {selectedCountry
        ? selectedCountry.Currency_symbol
          ? selectedCountry.Currency_symbol
          : selectedCountry.Currency_codes
        : ""}
      {productDetails.final_price ? (
        <> {Math.ceil(output).toFixed(2)} </>
      ) : (
        <>
          {" "}
          {Math.ceil(rangePrice.min).toFixed(2) +
            " - " +
            Math.ceil(rangePrice.max).toFixed(2)}{" "}
        </>
      )}
    </div>
  );
}

export function priceConverter(price, rate, selectedCountry, weight) {
  let Shipping_Charges_per_KG = selectedCountry?.Shipping_Charges_per_KG
    ? parseInt(selectedCountry.Shipping_Charges_per_KG)
    : 0;
  let Customs_and_Duties = selectedCountry?.Customs_and_Duties
    ? parseInt(selectedCountry.Customs_and_Duties)
    : 0;
  let Miscellaneous_Charges = selectedCountry?.Miscellaneous_Charges
    ? parseInt(selectedCountry.Miscellaneous_Charges)
    : 0;
  let Local_Country_Taxes = selectedCountry?.Local_Country_Taxes
    ? parseInt(selectedCountry.Local_Country_Taxes)
    : 0;

  let temp1 = Customs_and_Duties > 0 ? (Customs_and_Duties * price) / 100 : 0;
  let temp2 = Local_Country_Taxes > 0 ? (Local_Country_Taxes * price) / 100 : 0;

  let final_price =
    (price +
      temp1 +
      temp2 +
      Shipping_Charges_per_KG * parseFloat(weight).toFixed(2) +
      Miscellaneous_Charges) *
    rate;

  return final_price;
}
