import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/fontawesome-free-solid";
import ColourSlider from "../sliders/ColourSlider copy";
import Price from "../../functions/Price";
import { Button, createTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Card from "react-animated-3d-card";

export default function ProductView({
  showColorSlider = true,
  wMax,
  ...props
}) {
  let product = props.product;
  const navigate = useNavigate();

  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor) =>
    augmentColor({ color: { main: mainColor } });
  const theme = createTheme({
    palette: {
      anger: createColor("#F40B27"),
      apple: createColor("#5DBA40"),
      steelBlue: createColor("#5C76B7"),
      violet: createColor("#BC00A3"),
    },
  });
  function navigateFunction() {
    product?.selectedUniqueImage
      ? navigate(
          `/product-details/${
            product.Product_Code
          }?reload=${Date.now()}&colour=${product?.selectedUniqueImage}`
        )
      : navigate(
          `/product-details/${product.Product_Code}?reload=${Date.now()}`
        );
  }

  return (
    <div
      className={`productDiv row1 center  ${
        props.fullWidth ? "productDivFullWidth" : null
      }`}
      style={{ flexDirection: "column" }}
    >
      <div
        className={`row1 center pointer ${wMax}`}
        style={{ flexDirection: "column" }}
        // to={`/product-details/${
        //   product.Product_Code
        // }?reload=${Date.now()}&colour=${product?.selectedUniqueImage}`}
        onClick={navigateFunction}
      >
        <div className={`imgDiv ${props.fullWidth ? "imgDivFullWidth" : null}`}>
          {/* <Card> */}
          <LazyLoadImage
            src={
              product?.uniqueColourImages?.length > 0
                ? product.uniqueColourImages[0]?.link
                : ""
            }
            alt={product?.Group_Products}
            id={`image${product.Product_Code}`}
            style={{ width: "100%", borderRadius: "10px" }}
            effect="blur"
          />
          {/* </Card> */}
        </div>

        <div className="info" style={{ height: "80px", marginBottom: ".5rem" }}>
          <p className="name">{product.Group_Products}</p>
          <strong>
            <Price productDetails={product}></Price>
          </strong>
          <div className="reviews row1 center">
            <span>
              {[1, 2, 3, 4, 5].map((x) => (
                <FontAwesomeIcon
                  icon={faStar}
                  key={x}
                  style={{ color: "#d3b289" }}
                />
              ))}
            </span>
            <p style={{ marginLeft: "2px" }}>
              {Math.floor(Math.random() * 50)} reviews
            </p>
          </div>
          <div
            className="moreProductsInside"
            style={{ marginTop: "5px", fontSize: "12px", opacity: "0.7" }}
          >
            More products inside
          </div>
        </div>
      </div>

      {showColorSlider ? (
        <div className="row1 center" style={{ marginTop: "3rem" }}>
          {product?.uniqueColourImages.length > 1 && (
            <ColourSlider
              imageArr={product?.uniqueColourImages}
              product={product}
            />
          )}
        </div>
      ) : null}
      <div className="mobile">
        <Link
          to={`/product-details/${product.Product_Code}?reload=${Date.now()}`}
        >
          {/* <button className="addToBag fit-content">SHOP NOW</button> */}
          <Button
            variant="contained"
            sx={{ mt: 2, backgroundColor: "black", color: "white" }}
            size="small"
          >
            SHOP NOW
          </Button>
        </Link>
      </div>
    </div>
  );
}
