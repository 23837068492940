import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCollections,
  getCategoryData,
} from "../../../actions/productActions";
// import NavBarElement from './NavBarElement';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import NavBarElement from "./NavBarElement";
import NavBarElementForCollection from "./NavBarElementForCollection";

export const list = ["Living", "Mattresses", "Kids"];
export const list1 = ["Bedding", "Bath Linen"];
export const list2 = ["Hotels", "Jets & Yachts", "Gifting"];
export const list3 = ["Collections", "Bedding", "Bath Linen"];

export default function NavBar(props) {
  const navigate = useNavigate();

  const category = useSelector((state) => state.category);
  const { categoryData } = category;

  const [vars, setVars] = React.useState({ name: "" });

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getCategoryData());
  }, [dispatch]);

  function handleMouseOver(name) {
    if (window.innerWidth > 800) setVars({ ...vars, name });
    else {
      switch (true) {
        case list3.includes(name):
          navigate(`/all-collections/${name.replaceAll(/\s+/g, "-")}/all`);
      }
    }
  }

  function handleMouseOut() {
    setVars({ ...vars, name: "" });
  }

  const allCollections = useSelector((state) => state.allCollections);
  const { collectionsData } = allCollections;

  React.useEffect(() => {
    dispatch(getAllCollections());
  }, [dispatch, window.location.pathname]);

  return (
    <div
      className={props.border ? "navbar center border-bottom" : "navbar center"}
    >
      <Box sx={{ maxWidth: "90%" }} variant="scrollable">
        <Box sx={{ display: "flex" }}>
          {list1.map((x) => (
            <div
              key={x}
              style={{ flex: "0 0 auto", zIndex: "200" }}
              onMouseOver={() => handleMouseOver(x)}
              onMouseOut={() => handleMouseOut()}
            >
              <Typography
                label={x}
                className="name"
                onClick={() =>
                  navigate(`/all-collections/${x.replaceAll(/\s+/g, "-")}/all`)
                }
              >
                {x}
              </Typography>
              {vars.name && vars.name?.toLowerCase() === x?.toLowerCase() && (
                <NavBarElement
                  handleMouseOut={handleMouseOut}
                  categoryData={categoryData}
                  name={x}
                  url={`/all-collections/${x}`}
                />
              )}
            </div>
          ))}

          {list.map((x) => (
            <div
              key={x}
              style={{ flex: "0 0 auto" }}
              onMouseOver={() => handleMouseOver(x)}
              onMouseOut={() => handleMouseOut()}
            >
              <Typography label={x} className="name" key={x}>
                {x}
              </Typography>
              {vars.name && vars.name?.toLowerCase() === x?.toLowerCase() && (
                <NavBarElement
                  handleMouseOut={handleMouseOut}
                  categoryData={categoryData}
                  name={x}
                />
              )}
            </div>
          ))}

          <div
            style={{ flex: "0 0 auto" }}
            onMouseOver={() => handleMouseOver("COLLECTIONS")}
            onMouseOut={() => handleMouseOut()}
          >
            <Link to={"/all-collections/our-collections/all"}>
              <Typography className="name">Collections</Typography>
            </Link>
            {vars.name && vars.name?.toLowerCase() === "collections" && (
              <NavBarElementForCollection
                handleMouseOut={handleMouseOut}
                collectionsData={collectionsData?.collectionForNavbar}
                name={"COLLECTIONS"}
              />
            )}
          </div>

          {list2.map((x) => (
            <Typography label={x} key={x} className="name">
              {x}
            </Typography>
          ))}
        </Box>
      </Box>
    </div>
  );
}
