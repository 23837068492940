import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountryData, selectCountry } from "../../actions/productActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";

export default function SelectCountry(props) {
  const navigate = useNavigate();
  const { productDetails } = props;
  const paymentImageArray = [
    "https://awsbyadab.s3.amazonaws.com/uploads/one-time-used-images/wVbxr60YkKV0imsm",
    "https://awsbyadab.s3.amazonaws.com/uploads/one-time-used-images/ozBZ1kzB10acj0fG",
    "https://awsbyadab.s3.amazonaws.com/uploads/one-time-used-images/OXx2Oj5PYMe0swFh",
    "https://awsbyadab.s3.amazonaws.com/uploads/one-time-used-images/7g83dKMVGPQoq2VE",
    "https://awsbyadab.s3.amazonaws.com/uploads/one-time-used-images/nSyjZWNM5mKGmllw",
    "https://awsbyadab.s3.amazonaws.com/uploads/one-time-used-images/mltNN85PuhnFHFPB",
    "https://awsbyadab.s3.amazonaws.com/uploads/one-time-used-images/QwRfFBHhC90uSp1O",
  ];

  const [tempCountry, setTempCountry] = React.useState("");

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setTempCountry(event.target.value);
  };
  const country = useSelector((state) => state.country);

  const [open, setOpen] = React.useState(false);

  const handleSubmit = () => {
    const finalCountry =
      country.countryData &&
      country.countryData.find((x) => x.Country === tempCountry);

    if (finalCountry)
      dispatch(selectCountry(finalCountry, productDetails, navigate));
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const countryLocal = JSON.parse(localStorage.getItem("country"));

  React.useEffect(() => {
    setTimeout(() => {
      if (country.countryData && !countryLocal)
        fetch("https://api.country.is/")
          .then((response) => response.json())
          .then((data) => {
            for (const tempCountry of country.countryData) {
              if (tempCountry.Country_codes === data.country) {
                dispatch(selectCountry(tempCountry));
                break;
              }
            }
          })
          .catch((error) => console.log(error));
    }, 1);
  }, [country.countryData]);

  React.useEffect(() => {
    if (props.open) {
      setOpen(true);
    }
    if (!country || (country && !country.countryData))
      dispatch(getCountryData());
  }, []);

  return (
    <div>
      <div className="row1 center">
        {props.open && (
          <div>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "140vw",
                    // Set your width here
                  },
                },
              }}
            >
              <div style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                <p
                  style={{
                    textAlign: "right",
                    paddingTop: "10px",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                >
                  x
                </p>

                <p
                  style={{
                    fontSize: "medium",
                    letterSpacing: "1px",
                    textAlign: "center",
                  }}
                >
                  Please select your country, for your perfect bedding
                  dimensions
                </p>

                <DialogContent sx={{ width: "100%" }}>
                  {/* <label style={{ fontSize: "small", letterSpacing: "1px", fontWeight: "600" }}>Country</label> */}
                  <select
                    className="selectCountrySelect"
                    value={tempCountry}
                    onChange={handleChange}
                  >
                    <option>Select Country</option>
                    {country &&
                      country.countryData &&
                      country.countryData.map((x, i) => (
                        <option key={i} value={x.Country}>
                          {x.Country}
                        </option>
                      ))}
                  </select>

                  <div
                    style={{
                      marginTop: "1.5rem",
                      border: "1px solid lightgrey",
                      marginBottom: "1rem",
                    }}
                  ></div>

                  <p>Payments accepted</p>
                  <div style={{ overflow: "auto" }}>
                    <div style={{ display: "flex" }}>
                      {paymentImageArray.map((x) => (
                        <img
                          src={x}
                          alt=""
                          style={{ width: "4.5rem" }}
                          key={x}
                        ></img>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "0rem",
                      border: "1px solid lightgrey",
                      marginBottom: "1rem",
                    }}
                  ></div>

                  <p
                    style={{
                      fontSize: "small",
                      letterSpacing: "1px",
                      textAlign: "center",
                    }}
                  >
                    We deliver to more than 200 countries. if your country isn,t
                    listed above please contact us.
                  </p>

                  <div
                    className="row1 center"
                    style={{ flexDirection: "column", marginBottom: "2rem" }}
                  >
                    <button
                      className="addToBag"
                      style={{ width: "85%" }}
                      onClick={handleSubmit}
                    >
                      Confirm Country
                    </button>
                    <p
                      style={{
                        marginTop: "10px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </p>
                  </div>
                </DialogContent>
              </div>
            </Dialog>
          </div>
        )}
      </div>
    </div>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
