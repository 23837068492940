import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { PRODUCTS_SEARCH_RESET } from "../../../constants/productConstants";
import { useDispatch } from "react-redux";

export default function NavBarElement(props) {
  let checkCategories = props.name === "Bedding";

  const navigate = useNavigate(),
    dispatch = useDispatch();

  function closeMenuOptions() {
    props.handleMouseOut("");
  }

  function SearchHandler(search) {
    dispatch({ type: PRODUCTS_SEARCH_RESET });
    if (props.url) navigate(`${props.url}/${search}`);
    else navigate(`/products?search=${search}`);
    closeMenuOptions();
  }

  const data =
    props.categoryData &&
    props.categoryData.find(
      (x) => x?.name?.toLowerCase() === props?.name?.toLowerCase()
    );
  function clickHandler() {
    if (props?.name?.toLowerCase() === "collections")
      navigate("/all-collections/all/all");
    else SearchHandler(props?.name?.toLowerCase());

    closeMenuOptions();
  }

  function handleCategory(x) {
    if (x === "Flat Sheet Set") {
      return "Flat Bed Sheet";
    } else if (x === "Fitted Sheet Set") {
      return "Fitted-Bed-Sheet";
    } else if (x === "Duvet Cover Set") {
      return "Duvet-Cover";
    } else {
      return x;
    }
  }

  return data ? (
    <div className="navbarShow">
      <div className="navbarParent">
        {data &&
          typeof data === "object" &&
          Object.entries(data)?.map(([key, value], i) =>
            typeof value === "object" ? (
              <div className="navbarChild" key={i}>
                <div
                  className="heading hoverWeight pointer"
                  style={{
                    marginBottom: "10px",
                    marginLeft: "20px",
                    paddingTop: "10px",
                  }}
                  key={i}
                >
                  {value?.heading === "Categories" ? "" : value?.heading}
                </div>
                <div className="navbarContent">
                  {value?.data?.map((x, i) =>
                    value?.heading === "Categories" && checkCategories ? (
                      <Link
                        to={`/all-collections/${
                          x === "Quilt / Duvet Filler"
                            ? "Duvet-Filler"
                            : handleCategory(x.replaceAll(/\s+/g, "-"))
                        }/all`}
                        key={i}
                      >
                        <p
                          key={i}
                          onClick={() => SearchHandler(x?.toLowerCase())}
                        >
                          {x}
                        </p>
                      </Link>
                    ) : (
                      <Link
                        to={`/products?search=&${key}=${
                          x === "Cotton"
                            ? "100% Cotton"
                            : x === "Eucacel"
                            ? "100% Eucacel"
                            : x === "Egyption Cotton"
                            ? "100% Virgin Egyptian Cotton"
                            : x
                        }`}
                        key={i}
                      >
                        <p
                          key={i}
                          onClick={() => SearchHandler(x?.toLowerCase())}
                        >
                          {x}
                        </p>
                      </Link>
                    )
                  )}
                </div>
              </div>
            ) : null
          )}
      </div>
    </div>
  ) : (
    <div className="navbarShowcollection">
      <p onClick={() => clickHandler()} style={{ fontWeight: "600" }}>
        Coming Soon
      </p>
    </div>
  );
}
